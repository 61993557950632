<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M10.32,30.96v110.08h6.88v-110.08zM24.08,30.96v110.08h10.32v-110.08zM41.28,30.96v110.08h27.52v-110.08zM75.68,30.96v110.08h6.88v-110.08zM89.44,30.96v110.08h10.32v-110.08zM106.64,30.96v110.08h6.88v-110.08zM120.4,30.96v110.08h13.76v-110.08zM141.04,30.96v110.08h6.88v-110.08zM154.8,30.96v110.08h6.88v-110.08z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
