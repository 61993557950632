<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M0,27.52v6.88h96.6425c1.37063,0 3.1175,1.74688 3.1175,3.1175v93.2025h-34.7225c-1.63937,-9.75562 -10.11844,-17.2 -20.3175,-17.2c-10.19906,0 -18.67812,7.44438 -20.3175,17.2h-14.0825c-1.90812,0 -3.44,-1.53187 -3.44,-3.44v-30.96h-6.88v30.96c0,5.65719 4.66281,10.32 10.32,10.32h14.0825c1.63938,9.75563 10.11844,17.2 20.3175,17.2c10.19906,0 18.67813,-7.44437 20.3175,-17.2h52.245c1.63938,9.75563 10.11844,17.2 20.3175,17.2c10.19906,0 18.67813,-7.44437 20.3175,-17.2h3.7625c2.86219,0 5.375,-1.29 7.2025,-3.1175c1.8275,-1.8275 3.1175,-4.34031 3.1175,-7.2025v-33.0025c0,-3.87 -1.47812,-7.4175 -2.795,-10.105c-1.31687,-2.6875 -2.6875,-4.6225 -2.6875,-4.6225v-0.1075l-14.0825,-18.92h-0.1075v-0.1075c-3.05031,-3.81625 -7.94156,-8.815 -14.7275,-8.815h-27.52c-1.23625,0 -2.365,0.22844 -3.44,0.645v-14.7275c0,-5.50937 -4.48812,-9.9975 -9.9975,-9.9975zM0,41.28v6.88h61.92v-6.88zM0,55.04v6.88h51.6v-6.88zM110.08,58.48h13.76v30.96c0,2.86219 1.29,5.375 3.1175,7.2025c1.8275,1.8275 4.34031,3.1175 7.2025,3.1175h30.96v27.52c0,0.57781 -0.43,1.505 -1.1825,2.2575c-0.7525,0.7525 -1.67969,1.1825 -2.2575,1.1825h-3.7625c-1.63937,-9.75562 -10.11844,-17.2 -20.3175,-17.2c-10.19906,0 -18.67812,7.44438 -20.3175,17.2h-10.6425v-68.8c0,-0.57781 0.43,-1.505 1.1825,-2.2575c0.7525,-0.7525 1.67969,-1.1825 2.2575,-1.1825zM130.72,58.48h6.88c2.83531,0 6.78594,3.18469 9.245,6.235l14.0825,18.8125l0.1075,0.1075c0.06719,0.09406 0.99438,1.41094 2.0425,3.5475c0.87344,1.77375 1.33031,3.88344 1.6125,5.6975h-30.53c-0.57781,0 -1.505,-0.43 -2.2575,-1.1825c-0.7525,-0.7525 -1.1825,-1.67969 -1.1825,-2.2575zM0,68.8v6.88h41.28v-6.88zM0,82.56v6.88h30.96v-6.88zM44.72,120.4c7.64594,0 13.76,6.11406 13.76,13.76c0,7.64594 -6.11406,13.76 -13.76,13.76c-7.64594,0 -13.76,-6.11406 -13.76,-13.76c0,-7.64594 6.11406,-13.76 13.76,-13.76zM137.6,120.4c7.64594,0 13.76,6.11406 13.76,13.76c0,7.64594 -6.11406,13.76 -13.76,13.76c-7.64594,0 -13.76,-6.11406 -13.76,-13.76c0,-7.64594 6.11406,-13.76 13.76,-13.76z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
