<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M20.64,6.88c-7.56531,0 -13.76,6.19469 -13.76,13.76v137.6c-0.1075,0 -0.215,0 -0.3225,0c-1.89469,0.09406 -3.37281,1.70656 -3.27875,3.60125c0.09406,1.89469 1.70656,3.37281 3.60125,3.27875h20.64c1.23625,0.01344 2.39188,-0.63156 3.02344,-1.70656c0.61813,-1.075 0.61813,-2.39187 0,-3.46687c-0.63156,-1.075 -1.78719,-1.72 -3.02344,-1.70656v-130.72h17.2c0,2.53969 1.38406,4.71656 3.44,5.9125v86.5375c0,0.1075 0,0.215 0,0.3225v13.8675c-0.01344,1.10188 0.51063,2.13656 1.3975,2.795c0,0 15.11719,10.965 36.4425,10.965c21.32531,0 36.4425,-10.965 36.4425,-10.965c0.88688,-0.65844 1.41094,-1.69312 1.3975,-2.795v-13.76c0,-0.1075 0,-0.215 0,-0.3225v-86.645c2.05594,-1.19594 3.44,-3.37281 3.44,-5.9125h17.2v130.72c-0.1075,0 -0.215,0 -0.3225,0c-1.89469,0.09406 -3.37281,1.70656 -3.27875,3.60125c0.09406,1.89469 1.70656,3.37281 3.60125,3.27875h20.64c1.23625,0.01344 2.39188,-0.63156 3.02344,-1.70656c0.61813,-1.075 0.61813,-2.39187 0,-3.46687c-0.63156,-1.075 -1.78719,-1.72 -3.02344,-1.70656v-137.6c0,-7.56531 -6.19469,-13.76 -13.76,-13.76zM20.64,13.76h130.72c3.84313,0 6.88,3.03688 6.88,6.88v137.6h-6.88v-137.6h-130.72v137.6h-6.88v-137.6c0,-3.84312 3.03688,-6.88 6.88,-6.88zM58.48,27.52h55.04c0,2.53969 1.38406,4.71656 3.44,5.9125v84.925c-3.89687,2.09625 -17.46875,8.9225 -30.96,8.9225c-13.49125,0 -27.06312,-6.82625 -30.96,-8.9225v-84.925c2.05594,-1.19594 3.44,-3.37281 3.44,-5.9125zM55.04,125.8825c5.60344,2.96969 16.71625,8.2775 30.96,8.2775c14.24375,0 25.35656,-5.30781 30.96,-8.2775v6.235c-1.86781,1.27656 -13.45094,8.9225 -30.96,8.9225c-17.50906,0 -29.09219,-7.64594 -30.96,-8.9225z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
