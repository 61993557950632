<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M85.94625,6.85984c-0.71607,0.00209 -1.41365,0.22757 -1.99547,0.645l-71.78984,37.60484c-1.13334,0.5951 -1.84256,1.77024 -1.84094,3.05031v106.64c0.00019,1.89978 1.54022,3.43981 3.44,3.44h23.50219c0.37149,0.0614 0.75054,0.0614 1.12203,0h95.19797c0.11347,0.01916 0.22784,0.03261 0.34266,0.04031c0.11404,0.00793 0.2284,0.01017 0.34265,0.00672c0.14643,-0.00636 0.2923,-0.02207 0.43672,-0.04703h23.53578c1.89978,-0.00019 3.43981,-1.54022 3.44,-3.44v-106.64c0.00162,-1.28008 -0.7076,-2.45521 -1.84094,-3.05031l-71.78313,-37.60484c-0.61152,-0.43934 -1.35028,-0.66593 -2.10297,-0.645c-0.00224,0 -0.00448,0 -0.00672,0zM86,14.21016l68.8,36.03266v101.11719h-17.2v-86c-0.00019,-1.89978 -1.54022,-3.43981 -3.44,-3.44h-96.32c-1.89978,0.00019 -3.43981,1.54022 -3.44,3.44v86h-17.2v-101.11719zM41.28,68.8h89.44v82.56h-89.44zM75.68,137.6c-1.24059,-0.01754 -2.39452,0.63425 -3.01993,1.7058c-0.62541,1.07155 -0.62541,2.39684 0,3.46839c0.62541,1.07155 1.77935,1.72335 3.01993,1.7058h20.64c1.24059,0.01754 2.39452,-0.63425 3.01993,-1.7058c0.62541,-1.07155 0.62541,-2.39684 0,-3.46839c-0.62541,-1.07155 -1.77935,-1.72335 -3.01993,-1.7058z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
